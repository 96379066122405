.error-text {
  color: #d32f2f;
  font-size: 0.75rem;
  margin-left: 0.85rem;
  letter-spacing: 0.03333em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.error-text.customization ul {
  list-style: circle;
  margin-left: 3rem;
  margin-right: auto;
}

.error-text.customization u {
  text-decoration: underline;
}

.info-text {
  color: #737373;
  font-size: 0.75rem;
  margin-left: 0.85rem;
  letter-spacing: 0.03333em;
}

.css-wgai2y-MuiFormLabel-asterisk {
  color: #ff0000;
}

.asterix::after {
  content: " *";
  color: #ff0000;
}

.header__top-nav {
  font-family: Segoe UI;
}

.header__menu {
  font-family: Segoe UI;
}

.bread-and-message {
  font-family: Segoe UI;
}

.item__details {
  font-family: Segoe UI;
}

.order-history {
  font-family: Segoe UI;
}

.wish-list {
  font-family: Segoe UI;
}

.quick-order {
  font-family: Segoe UI;
}

.my-preferences {
  font-family: Segoe UI;
}

.contact-us {
  font-family: Segoe UI;
}

.user__registration {
  font-family: Segoe UI;
}

.login-comp {
  font-family: Segoe UI;
}

.cart-fonts {
  font-family: Segoe UI;
}

.left-menu {
  font-family: Segoe UI;
}

.product-listing {
  font-family: Segoe UI;
}

.check-out {
  font-family: Segoe UI;
}

.WebpartParamSelector {
  font-family: Segoe UI;
}

.password-rules {
  padding: 1rem !important;
  font-style: italic;
}
.password-rules label {
  font-weight: bold;
  background-color: #f1f1f1;
  display: flex;
  flex-grow: 1;
  padding: 0.5rem 1rem;
}
.password-rules li {
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
}
.password-rules .icon {
  height: 16px;
  margin: 0.4rem;
  display: flex;
}
.password-rules .success {
  color: #008000;
}
.password-rules .error {
  color: #ff0000;
}
.password-rules .additional-password {
  font-style: italic;
  background-color: #f1f1f1;
  padding: 0 1rem 0 0;
}
.password-rules span {
  font-weight: bold;
  display: flex;
  flex-grow: 1;
  padding: 0.5rem 1rem;
  color: #000;
}
.password-rules li {
  padding: 1rem;
}

.srs-custom-button, .srs-custom-button:hover {
  background-color: #001A70;
  color: #fff !important;
}

.btn-primary, .btn-primary:hover {
  background: #001A70;
  color: #fff !important;
  border: 1px solid #dddddd;
}

.css-189e9pj-MuiButtonBase-root-MuiButton-root {
  background: #001A70 !important;
  color: #fff !important;
}

.btn-check:focus + .btn, .btn:focus {
  box-shadow: unset !important;
}

.carousel .carousel-indicators button {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #001A70;
  margin-bottom: -15px;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23343a40' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
  background-size: 50%;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23343a40' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
  background-size: 50%;
}

.carousel-product-image .carousel-indicators {
  bottom: -10px;
}

.carousel-welcome {
  padding-bottom: 20px;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control:focus {
  color: #001A70;
  background-color: #fff;
  border: 1px solid #ced4da;
  outline: 1;
  box-shadow: unset;
}

@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1700px;
  }
}
.alert {
  position: fixed;
  top: 10%;
  right: 0;
  width: 40%;
  z-index: 999 !important;
}

/* For Mobile View */
@media screen and (min-width: 320px) and (max-width: 991px) {
  .alert {
    width: 70%;
  }
}
.breadcrumb {
  padding: 0.2rem 0;
  margin-bottom: 0rem;
  display: flex;
  flex-direction: row;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}

@media (max-width: 768px) {
  .breadcrumb {
    flex-direction: column;
  }
}
.bread-and-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .bread-and-message {
    flex-direction: column;
    align-items: flex-start;
  }
}
.back-to-top {
  padding: 1rem 0;
  cursor: pointer;
}
.back-to-top .back-to-top-inactive .text {
  display: none;
}
.back-to-top .back-to-top-inactive:hover .text {
  display: block;
  background-color: #f2f2f2;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.35);
  border-color: #d7d7d7;
  border-radius: 56px;
  width: 105.9px;
  text-align: left;
  font-size: 11px;
  padding: 0.5rem 0.1rem 0.5rem 0.9rem;
  position: absolute;
  z-index: -99;
}

.lds-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #cef;
  border-color: #cef transparent #cef transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-container {
  overflow-y: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.loader {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  position: absolute;
}

.downloadItem {
  margin: 0 0 0 1rem;
  text-decoration: underline;
  color: #0d6efd;
}
.downloadItem:hover {
  color: #0d6efd;
}

.shippingToOtherCountry {
  margin-top: 8px;
  padding: 4px 8px;
  background-color: #e6e6e6;
}
.shippingToOtherCountry a {
  color: #0d6efd;
  text-decoration: underline;
}
.shippingToOtherCountry a:hover {
  color: #0d6efd;
}

.imagesDetail {
  width: 80%;
  height: auto;
  background: #fff;
}

@media screen and (max-width: 767px) {
  .imagesDetail {
    width: 92vw;
  }
}
.swiper-slide {
  height: fit-content !important;
}

.container__zoomed_image {
  justify-content: space-around;
}

.js-image-zoom__zoomed-image {
  box-shadow: 0px 10px 39px -19px rgb(0, 0, 0);
  border: 5px solid #fff;
  border-radius: 10px;
  background-color: #fff;
}

.btn-zoom-close-mobile-zoomedImg {
  background: #eaeaea;
  border: 1px solid #ccc;
  border-radius: 10px 10px 0 0;
  width: 100px;
  display: none;
  margin-right: 15vw;
  box-shadow: 11px -7px 23px -15px rgba(0, 0, 0, 0.75);
  font-size: 14px;
  height: 32px;
}

.container__zoomed_image {
  display: flex;
  align-items: center;
}

.image-disclaimer {
  text-align: center;
  font-weight: bold;
}

.doc-title-4 {
  padding: 15px 15px 8px 15px !important;
  margin: 0 !important;
  font-size: 18px !important;
  font-weight: bold !important;
}

.doc-list-ul {
  list-style: disc;
  padding-left: 35px;
}

.doc-list-ul li a {
  color: #0d6efd;
  text-decoration: underline;
}

.doc-list-ul li a:hover {
  color: white;
  text-decoration: underline;
}

.footer {
  background: unset;
  color: unset;
  border-top: 1px solid #000;
  margin: auto;
  padding: 1rem 0;
  clear: both;
}
.footer #footerPrivacySettings {
  text-decoration: underline;
  cursor: pointer;
}
.footer a {
  color: unset;
  text-decoration: underline;
}
.footer__logo {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  text-align: right;
}
.footer .bosch_logo {
  width: 4.5rem;
  margin-left: auto;
}

/* For Mobile View */
@media screen and (min-width: 320px) and (max-width: 991px) {
  .footer a {
    padding: 0.4rem 0 0.4rem;
  }
  .footer__logo {
    margin: 1rem 0;
    /* display: flex */
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .footer a:not(:first-child) {
    margin: 0;
  }
}
.password-rules {
  padding: 1rem !important;
  font-style: italic;
}
.password-rules label {
  font-weight: bold;
  background-color: #f1f1f1;
  display: flex;
  flex-grow: 1;
  padding: 0.5rem 1rem;
}
.password-rules li {
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
}
.password-rules .icon {
  height: 16px;
  margin: 0.4rem;
  display: flex;
}
.password-rules .success {
  color: #008000;
}
.password-rules .error {
  color: #ff0000;
}
.password-rules .additional-password {
  font-style: italic;
  background-color: #f1f1f1;
  padding: 0 1rem 0 0;
}
.password-rules span {
  font-weight: bold;
  display: flex;
  flex-grow: 1;
  padding: 0.5rem 1rem;
  color: #000;
}
.password-rules li {
  padding: 1rem;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
  margin: auto;
}
.header__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.header__row .logo {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.header__logo {
  flex: 0 0 50%;
}
.header__search {
  display: inline-block;
  margin-left: auto;
}
.header__search > a {
  margin-left: 0.6rem;
}
.header__links {
  display: flex;
  flex-grow: 1;
  background-color: #001A70;
  padding: 0.3rem 1rem 0.3rem 1rem;
  align-items: center;
  font-weight: 600;
}
.header__top-nav-mobile .header__top-nav {
  display: none;
}
.header__button, .header__button:hover {
  color: white;
  text-decoration: none;
  padding-right: 1rem;
}
.header__top-nav {
  justify-content: flex-end;
}
.header__myAccount {
  flex-grow: 0 !important;
}
.header__welcome-text {
  color: #000;
  font-weight: bold;
  margin-bottom: 10px;
}
.header__input-group-append {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-17%, -50%);
  z-index: 800;
}
.header__right-menu {
  padding-right: 10px;
}
.header__right-menu .header-icon {
  font-size: 1.4rem;
}
.header__right-menu #lblCartItemsCount {
  position: absolute;
  bottom: 30px;
  min-width: 16px;
  width: auto;
  height: 10px;
  left: 14%;
  transform: translate(40%, 40%);
}
.header__right-menu span#lblLoanCartItemsCount {
  position: absolute;
  bottom: 32px;
  min-width: 16px;
  width: auto;
  height: 10px;
  left: -13%;
  transform: translate(40%, 40%);
  text-align: center;
}
.header__right-menu a#headerLoanCartIcon {
  top: 6px;
}
.header__right-menu #CartItemCount {
  display: flex;
  font-size: 15px !important;
  border-radius: 50%;
  justify-content: center;
}
.header__right-menu span.srs-icon-loan-hand.font-lg {
  position: relative;
  top: auto;
}
.header__right-menu #LoanCartItemCount {
  display: flex;
  font-size: 15px !important;
  border-radius: 50%;
  justify-content: center;
}
.header__right-menu .header__button.mobile {
  display: none;
}
.header__lang {
  position: absolute;
}
.header__lang .selected {
  width: 50px;
  background-color: #001A70;
  color: #fff;
  padding: 5px;
  font-weight: 700;
  font-size: 12px;
  border-radius: 2px;
  margin-right: 20px;
  cursor: pointer;
}
.header__lang .dropdown {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 2px;
  padding: 5px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.header__lang .dropdown li {
  text-align: center;
  font-size: 12px;
  padding: 5px;
  cursor: pointer;
}
.header__lang .dropdown li:hover {
  color: #001A70;
}
.header .top-log-menu {
  display: inline;
}
.header .log-out {
  text-decoration: none;
  padding: 0;
  color: white;
}
.header .edit-content-button {
  text-decoration: none;
  padding: 0 0 0 1.6rem;
  color: white;
}
.header .cart-icon {
  height: 26px;
}
.header .te {
  display: block;
}
.header .help-icon {
  display: none;
}

/* For Mobile View */
@media screen and (min-width: 320px) and (max-width: 991px) {
  .help-icon {
    display: block !important;
  }
  a.login-label {
    color: #000;
  }
  .te {
    display: none !important;
  }
  .header__button {
    color: #ff0000;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.3rem 0;
  }
  .header__links {
    padding: 0;
    background-color: unset;
  }
  .header__row {
    align-items: center;
    display: flex !important;
  }
  .header__top-nav-mobile .header__top-nav {
    display: contents;
    flex-direction: column;
    align-content: flex-start;
    font-weight: bold;
    padding: 0.2rem 0;
    border-top: 1px solid #000;
  }
  .header__top-nav-mobile .header__top-nav > a {
    margin-left: 0rem;
    color: #343a40;
  }
  .header__button, .header__button:hover {
    color: #000;
    text-decoration: none;
    padding-right: 1rem;
  }
  .header__myAccount {
    padding: 0 !important;
    margin: 0.4rem 0px;
  }
  .header__top-nav-desktop .header__top-nav {
    display: none;
  }
  .header__right-menu {
    text-align: start;
    width: 100%;
  }
  .header__right-menu .desktop-cart {
    display: none;
  }
  .header__right-menu .header__button.mobile {
    display: flex;
    border-top: 1px solid #6c757d;
    border-bottom: 1px solid #6c757d;
    padding-top: 8px;
  }
  .header__right-menu .header__button.mobile .srs-icon-help-circle {
    font-size: 26px;
    padding: 5px;
    margin: 1px;
    font-weight: bolder;
  }
  .header__right-menu #lblCartItemsCount {
    position: absolute;
    bottom: 35px;
    min-width: 16px;
    width: auto;
    height: 10px;
    left: 11.5%;
    transform: translate(40%, 70%);
  }
  .header__right-menu span.srs-icon-loan-hand.font-lg {
    position: relative;
    top: 13px;
  }
  .header__right-menu #CartItemCount {
    display: flex;
    font-size: 15px !important;
    border-radius: 50%;
    justify-content: center;
  }
  .header__right-menu #LoanCartItemCount {
    display: flex;
    font-size: 15px !important;
    border-radius: 50%;
    justify-content: center;
  }
  .header__right-menu span.srs-icon-cart-circle.h3 {
    font-size: 1.8rem;
    margin-right: -0.5rem;
  }
  .header .top-log-menu {
    display: flex;
    flex-direction: column;
    align-items: self-start;
  }
  .header .log-out {
    font-weight: bold;
  }
  .header .edit-content-button {
    font-weight: bold;
    padding: 0.4rem 0 0 0;
  }
}
.list-group-item {
  padding: 0.2rem 1rem 0.2rem;
  font-size: 16px;
  line-height: 1.5;
}

/* For Mobile View */
@media screen and (min-width: 320px) and (max-width: 991px) {
  .left-menu {
    display: block;
    margin-left: 0;
    left: 0;
    position: fixed;
    top: 0;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    height: 100vh;
    width: 80%;
    overflow-y: auto;
    z-index: 999;
  }
  .left-menu .left-menu-close,
  .left-menu .left-menu-close:hover {
    position: absolute;
    right: 0.6rem;
    border-radius: 1rem;
    height: 30px;
    color: #000;
    background: #fff;
    width: 30px;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.list-group-item + .list-group-item.active {
  background: #001A70;
}

@font-face {
  font-family: "srs-font-icons";
  src: url("../../node_modules/srs.sharedcomponents/lib/esm/sass/custom-icons/srs-font-icons.eot?jktbfe");
  src: url("../../node_modules/srs.sharedcomponents/lib/esm/sass/custom-icons/srs-font-icons.eot?jktbfe#iefix") format("embedded-opentype"), url("../../node_modules/srs.sharedcomponents/lib/esm/sass/custom-icons/srs-font-icons.ttf?jktbfe") format("truetype"), url("../../node_modules/srs.sharedcomponents/lib/esm/sass/custom-icons/srs-font-icons.woff?jktbfe") format("woff"), url("../../node_modules/srs.sharedcomponents/lib/esm/sass/custom-icons/srs-font-icons.svg?jktbfe#srs-font-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=srs-icon-], [class*=" srs-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "srs-font-icons", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.srs-icon-wishlist-o:before {
  content: "\e911";
}

.srs-icon-help-circle:before {
  content: "\e920";
}

.srs-icon-cart:before {
  content: "\e909";
}

.srs-icon-cart-add:before {
  content: "\e908";
}

.srs-icon-cart-added:before {
  content: "\e906";
}

.srs-icon-cart-remove:before {
  content: "\e907";
}

.srs-icon-loan-cart:before {
  content: "\e90e";
}

.srs-icon-wishlist:before {
  content: "\e910";
}

.srs-icon-cart-circle:before {
  content: "\e90c";
}

.srs-icon-cart-add-circle:before {
  content: "\e90a";
}

.srs-icon-cart-added-circle:before {
  content: "\e90b";
}

.srs-icon-cart-remove-circle:before {
  content: "\e90d";
}

.srs-icon-loan-cart-circle:before {
  content: "\e90f";
}

.srs-icon-cart-add-color-circle .path1:before {
  content: "\e91a";
  color: rgb(27, 20, 100);
}

.srs-icon-cart-add-color-circle .path2:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(27, 20, 100);
}

.srs-icon-cart-add-color-circle .path3:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(0, 0, 255);
}

.srs-icon-cart-added-color-circle .path1:before {
  content: "\e917";
  color: rgb(27, 20, 100);
}

.srs-icon-cart-added-color-circle .path2:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(27, 20, 100);
}

.srs-icon-cart-added-color-circle .path3:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(0, 146, 69);
}

.srs-icon-cart-remove-color-circle .path1:before {
  content: "\e914";
  color: rgb(27, 20, 100);
}

.srs-icon-cart-remove-color-circle .path2:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(27, 20, 100);
}

.srs-icon-cart-remove-color-circle .path3:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(255, 0, 0);
}

.srs-icon-wishlist-added-color:before {
  content: "\e913";
  color: #f00;
}

.srs-icon-wishlist-add-color:before {
  content: "\e910";
  color: #b3b3b3;
}

.srs-icon-cart-add-color .path1:before {
  content: "\e902";
  color: rgb(27, 20, 100);
}

.srs-icon-cart-add-color .path2:before {
  content: "\e903";
  margin-left: -0.982421875em;
  color: rgb(0, 0, 255);
}

.srs-icon-cart-added-color .path1:before {
  content: "\e904";
  color: rgb(27, 20, 100);
}

.srs-icon-cart-added-color .path2:before {
  content: "\e905";
  position: absolute;
  top: 1px;
  left: 0;
  color: rgb(0, 146, 69);
}

.srs-icon-cart-remove-color .path1:before {
  content: "\e900";
  color: rgb(27, 20, 100);
}

.srs-icon-cart-remove-color .path2:before {
  content: "\e901";
  margin-left: -1.0361328125em;
  color: rgb(255, 0, 0);
}

.srs-icon-wishlistCart-add-color:before {
  content: "\e912";
  color: #b3b3b3;
}

.srs-icon-loan-hand:before {
  content: "\e914";
}

.srs-icon-loan-hand-plus:before {
  content: "\e91e";
}

.srs-icon-loan-hand-b-plus .path1:before {
  content: "\e915";
  color: rgb(0, 0, 0);
}

.srs-icon-loan-hand-b-plus .path2:before {
  content: "\e916";
  margin-left: -1.2041015625em;
  color: rgb(0, 0, 0);
}

.srs-icon-loan-hand-b-plus .path3:before {
  content: "\e917";
  margin-left: -1.2041015625em;
  color: rgb(0, 0, 255);
}

.srs-icon-loan-hand-added .path1:before {
  content: "\e918";
  color: rgb(0, 0, 0);
}

.srs-icon-loan-hand-added .path2:before {
  content: "\e919";
  /*margin-left: -1.265625em*/
  color: rgb(0, 0, 0);
}

.srs-icon-loan-hand-added .path3:before {
  content: "\e91a";
  margin-left: -1.265625em;
  color: rgb(0, 146, 69);
}

.srs-icon-cart-header:before {
  content: "\e91f";
}

.colorized::before {
  color: #002469;
}

@media screen and (min-width: 320px) and (max-width: 991px) {
  .srs-icon-cart-added-color .path2:before {
    position: absolute;
    top: 0;
    left: 50%;
    color: #009245;
    transform: translate(-50%, 0px);
  }
}
.welcome {
  display: flex;
  justify-content: center;
}
.welcome img.img-responsive {
  width: 100%;
  height: 100%;
}
.welcome img {
  padding: 0.4rem 0;
}
.welcome div#welcomeZoneImage img {
  width: 100% !important;
  min-height: 180px;
  height: auto !important;
  max-height: 100% !important;
}
.welcome div#mainZoneImage img {
  width: 100%;
  height: 100%;
}
.welcome div#additionalZone1Image img {
  width: 100%;
  height: auto;
}
.welcome div#additionalZone2Image img {
  width: 100%;
  height: auto;
}
.welcome div#additionalZone3Image img {
  width: 100%;
  height: auto;
}

.carousel-control-prev {
  opacity: 1;
}

.carousel-control-next {
  opacity: 1;
}

/* For Mobile View */
@media screen and (min-width: 320px) and (max-width: 991px) {
  .first-image img {
    width: 100%;
  }
}
.outOfStockMessage {
  color: #ff0000;
}

.cart {
  padding: 1rem 0 1rem;
}
.cart__header {
  display: initial;
  background-color: #fff !important;
  top: 0;
  z-index: 997;
}
.cart__header .sticky {
  position: sticky;
  background-color: #fff !important;
  top: 0;
  z-index: 997;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.cart__header .total-string {
  font-weight: bold;
}
.cart__header .total {
  color: #001A70;
  font-weight: bolder;
}
.cart__header .second-row {
  padding: 0.7rem 0.7rem 0;
}
.cart__header .second-row .divlnkUpdateStockAvailability {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}
.cart__header .second-row .action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: italic;
  font-size: 15px;
}
.cart__header .second-row .lnkCartUpdate,
.cart__header .second-row .lnkCartContinueShopping,
.cart__header .second-row .lnkCartClearCart {
  justify-content: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0 0 0 0.5rem;
  color: #343a40;
}
.cart .wishlist-item-move-to-cart {
  cursor: pointer;
}
.cart .qty-label {
  position: absolute;
  top: -8px;
  left: 44%;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.75rem;
  background: #fff;
  z-index: 99;
}
.cart a.lnkCartClearCart {
  background: #0d6efd !important;
}
.cart .product-counter input {
  margin: 0;
  padding: 16px 5px 16px 5px;
  height: auto;
}
.cart .product-counter button {
  background-color: #fff;
  border: 1px solid #ced4da;
  color: #000 !important;
}
.cart .product-counter button.left {
  width: 30px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.cart .product-counter button.right {
  width: 30px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 16px 5px 16px 5px;
  height: auto;
}
.cart .wishlist-row {
  padding: 0.7rem 1.5rem;
}

/* For Mobile View */
@media screen and (min-width: 320px) and (max-width: 991px) {
  .cart__header .second-row {
    padding: 0.5rem 0 0.5rem 0;
  }
  .cart__header .second-row .divlnkUpdateStockAvailability {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }
  .cart__header .second-row .action-buttons {
    text-align: center;
  }
  .cart__header .second-row .action-buttons .lnkCartUpdate,
  .cart__header .second-row .action-buttons .lnkCartContinueShopping,
  .cart__header .second-row .action-buttons .lnkCartClearCart {
    margin: 0.5rem 0;
  }
  .productNotAvailable {
    display: flex;
    flex-direction: column;
  }
  .wishlist-row {
    text-align: center;
  }
}
.registration {
  width: 76%;
  padding: 1rem 0 1rem;
  margin: 0 auto;
}

/* For Mobile View */
@media screen and (min-width: 320px) and (max-width: 991px) {
  .registration {
    width: 100%;
  }
  .registration .password-rules label {
    justify-content: center;
  }
  .registration .password-rules li {
    justify-content: space-between;
    padding: 0.4rem 0;
  }
}
.adyen-checkout__fieldset__fields {
  pointer-events: none;
  opacity: 0.4;
  background-image: linear-gradient(to top, #c3c3c4 0%, #e1e2e3 100%);
  color: #000;
  background-color: #c3c3c4;
}

.checkout .address-information {
  padding: 0.6rem 0;
  display: block;
}
.checkout .new-address-toggler {
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}
.checkout .order-summury {
  display: flex;
  justify-content: space-between;
  padding: 0.6rem 0.6rem;
}
.checkout .order-summury .value.total, .checkout .order-summury .label.total {
  font-weight: bold;
}
.checkout .order-summury p {
  justify-content: center;
  align-items: center;
  align-self: center;
}
.checkout .save-button {
  background: #001A70;
}
.checkout .instuction.accordion-body {
  padding: 0.6rem;
}
.checkout .accordion-button:not(.collapsed) {
  color: unset;
  background-color: unset;
  box-shadow: unset;
}
.checkout button.accordion-button {
  padding: 10px 0;
}

.ddlUCTMessage {
  font-size: 12px;
  color: #ff0000;
  margin-bottom: 20px;
}

@media (max-width: 1199px) {
  .shippingAddressOption {
    margin-top: 7px;
    margin-bottom: 8px;
  }
}
.shippingAddressOption:first-of-type {
  margin-top: 13px;
}

.quick-order {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.quick-order .selector {
  background-color: #f1f1f1;
  padding: 1rem 1rem 0.5rem;
}
.quick-order .selector .MuiInputBase-root {
  background: #fff;
}
.quick-order .wrapper {
  padding: 0 1rem 0.5rem;
}
.quick-order .wrapper .item-label {
  position: absolute;
  top: -10px;
  left: 13px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.8rem;
  background: #fff;
  width: 50px;
  padding: 0.1rem 1.1px 0 3px;
}
.quick-order .wrapper .qty-label {
  position: absolute;
  top: -1px;
  left: 44%;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.8rem;
  background: #fff;
  z-index: 99;
}
.quick-order .button-wrapper {
  padding: 1rem 0 0;
  border-top: 1px solid #f1f1f1;
}
.quick-order .cancel-button {
  cursor: pointer;
  background: unset;
  padding: 1rem;
  color: #000;
  width: 100%;
  border: 1px solid;
}
.quick-order .add-button {
  cursor: pointer;
  background: #001A70;
  padding: 1rem;
  color: #fff;
  width: 100%;
}
.quick-order .counter-quick-order input {
  margin: 0;
  padding: 16px 5px 16px 5px;
  height: auto;
}
.quick-order .counter-quick-order button.left {
  background-color: #fff;
  border: 1px solid #ced4da;
  width: 30px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #000 !important;
}
.quick-order .counter-quick-order button.right {
  background-color: #fff;
  border: 1px solid #ced4da;
  width: 30px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 16px 5px 16px 5px;
  height: auto;
  color: #000 !important;
}
.quick-order .selected-sku {
  padding: 16px;
  background: unset;
  border-radius: 10px;
  width: 100%;
}
.quick-order .remove-product {
  vertical-align: middle;
  font-size: 33px;
  color: #ff0000;
  padding: 13px 0;
  cursor: pointer;
}

/* For Mobile View */
@media screen and (min-width: 320px) and (max-width: 991px) {
  .quick-order .cancel-button {
    margin: 1rem 0 0 0;
  }
  .quick-order .add-button {
    margin: 1rem 0 0 0;
  }
  .quick-order .form-label {
    margin: 1rem 0 0 0;
  }
}
.carousel-product-image {
  padding-bottom: 20px;
}

.item-details {
  padding: 1rem;
}
.item-details .mobile-name {
  display: none;
}
.item-details .carousel-control-prev {
  z-index: 8;
  padding-right: 3rem;
}
.item-details .carousel-control-next {
  z-index: 8;
  padding-left: 3rem;
}
.item-details .image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid lightgray;
  text-align: center;
  height: 100%;
}
.item-details .image-wrapper .active.carousel-item {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  z-index: 7 !important;
}
.item-details .image-wrapper .small-image {
  width: fit-content !important;
}
.item-details .image-wrapper .medium-image {
  width: 300px !important;
  margin-left: 6%;
}
.item-details .image-wrapper .big-image {
  width: 480px !important;
  margin-left: 4%;
}
@media screen and (max-width: 980px) {
  .item-details .image-wrapper .big-image, .item-details .image-wrapper .medium-image {
    width: 240px !important;
    margin-left: 2%;
  }
}
.item-details #lblItemDescription ul {
  list-style: disc;
}
.item-details #lblItemDescription ol, .item-details #lblItemDescription ul {
  padding-left: 2rem;
}
.item-details .card-info {
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid lightgray;
}
.item-details .card-info .desktop-name {
  display: block;
}
.item-details .swiper-button-next {
  color: #343a40;
}
.item-details .swiper-button-prev {
  color: #343a40;
}
.item-details .price-rate {
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
}
.item-details .item-quantity input {
  margin: 0;
  padding: 16px 5px 16px 5px;
  height: auto;
}
.item-details .item-quantity button.left {
  background-color: #fff;
  border: 1px solid #ced4da;
  width: 30px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #000 !important;
}
.item-details .item-quantity button.right {
  background-color: #fff;
  border: 1px solid #ced4da;
  width: 30px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 16px 5px 16px 5px;
  height: auto;
  color: #000 !important;
}

/* For Mobile View */
@media screen and (min-width: 320px) and (max-width: 991px) {
  .item-details__add-to-cart, .item-details__add-to-wish {
    display: block;
  }
  .item-details .mobile-name {
    display: block;
  }
  .item-details .image-wrapper {
    text-align: center;
  }
  .item-details .image-wrapper img {
    position: relative;
    display: block;
  }
  .item-details .card-info .desktop-name {
    display: none;
  }
  .item-details .card-info a {
    color: blue !important;
    text-decoration: underline !important;
  }
}
@media screen and (max-width: 980px) {
  .w-sm-90 {
    width: 90% !important;
  }
}
.product-images-slider .swiper-slide {
  position: relative;
  width: 20%;
}
.product-images-slider .swiper-slide img {
  width: 80%;
}

.product-images-slider-thumbs .swiper-slide {
  cursor: pointer;
  border: 1px solid #dddbdb;
}
.product-images-slider-thumbs .swiper-slide-thumb-active {
  border-color: black;
}
.product-images-slider-thumbs-wrapper {
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  position: relative;
}
.product-images-slider-thumbs-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.my-preferences {
  width: 76%;
  padding: 1rem 0 1rem;
  margin: 0 auto;
}
@media (max-width: 991px) {
  .my-preferences {
    width: 100%;
  }
}
.my-preferences .accordion {
  padding: 1rem 0;
}
.my-preferences-body {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.my-preferences-body button.accordion-button {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.my-preferences .password-rules {
  padding: 1rem;
  font-style: italic;
}
.my-preferences .password-rules label {
  font-weight: bold;
  background-color: #f1f1f1;
  display: flex;
  flex-grow: 1;
  padding: 0.5rem 1rem;
}
.my-preferences .password-rules label li {
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
}
.my-preferences .password-rules label li .icon {
  height: 16px;
  margin: 0.4rem;
  display: flex;
}
.my-preferences .password-rules label li .success {
  color: #008000;
}
.my-preferences .password-rules label .additional-password li {
  padding: 1rem;
}
.my-preferences .customer-support-info {
  width: 100%;
  font-size: 14px;
  margin-top: 30px;
  text-align: center;
  line-height: 1.8em;
}

@media (max-width: 768px) {
  .accordion-body-retailer-my-preferences {
    padding: 0;
    margin-top: 10px;
  }
}

.password-rules {
  padding: 1rem !important;
  font-style: italic;
}
.password-rules label {
  font-weight: bold;
  background-color: #f1f1f1;
  display: flex;
  flex-grow: 1;
  padding: 0.5rem 1rem;
}
.password-rules li {
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
}
.password-rules .icon {
  height: 16px;
  margin: 0.4rem;
  display: flex;
}
.password-rules .success {
  color: #008000;
}
.password-rules .error {
  color: #ff0000;
}
.password-rules .additional-password {
  font-style: italic;
  background-color: #f1f1f1;
  padding: 0 1rem 0 0;
}
.password-rules span {
  font-weight: bold;
  display: flex;
  flex-grow: 1;
  padding: 0.5rem 1rem;
  color: #000;
}
.password-rules li {
  padding: 1rem;
}

.wish-list {
  padding: 1rem 0.8rem 1rem;
}
.wish-list__row {
  display: flex;
  align-items: center;
}
.wish-list__header {
  padding: 1rem 1rem;
  position: sticky;
  background-color: #fff;
  top: 0;
  z-index: 998;
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
}
.wish-list__header .total-string {
  font-weight: bold;
}
.wish-list__header .wishlist-item-total,
.wish-list__header .total {
  color: #001A70;
  font-weight: bolder;
}
.wish-list__header .second-row {
  padding: 0.5rem 0 0.5rem 0;
}
.wish-list__header .second-row .divlnkUpdateStockAvailability {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.wish-list__header .second-row .action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wish-list__header .second-row .lnkCartUpdate,
.wish-list__header .second-row .lnkCartContinueShopping,
.wish-list__header .second-row .lnkCartClearCart {
  cursor: pointer;
}
.wish-list .qty-label {
  position: absolute;
  top: -8px;
  left: 44%;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.75rem;
  background: #fff;
  z-index: 99;
}
.wish-list .header-links {
  padding: 0 1.05rem 0 0;
}
.wish-list .wishlist-item-move-to-cart {
  cursor: pointer;
}
.wish-list .product-counter input {
  margin: 0;
  padding: 16px 5px 16px 5px;
  height: auto;
}
.wish-list .product-counter button.left {
  background-color: #fff;
  border: 1px solid #ced4da;
  width: 30px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #000 !important;
}
.wish-list .product-counter button.right {
  background-color: #fff;
  border: 1px solid #ced4da;
  width: 30px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 16px 5px 16px 5px;
  height: auto;
  color: #000 !important;
}

/* For Mobile View */
@media screen and (min-width: 320px) and (max-width: 991px) {
  .wish-list__header .second-row {
    padding: 0.5rem 0 0.5rem 0;
  }
  .wish-list__header .second-row .divlnkUpdateStockAvailability {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .wish-list__header .second-row .action-buttons {
    text-align: center;
  }
  .wish-list .productNotAvailable {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .wishlist-row {
    text-align: center;
  }
  .wishlist-row .badge {
    white-space: unset;
  }
}
.order-history .rdrDateRangePickerWrapper {
  justify-content: center;
  width: 100%;
}
.order-history .rdrDateRangePickerWrapper .rdrCalendarWrapper .rdrDateRangeWrapper {
  display: none;
}
.order-history #demo-multiple-checkbox {
  padding: 21px;
}

/* For Mobile View */
@media screen and (min-width: 320px) and (max-width: 991px) {
  .order-history .rdrDateRangePickerWrapper {
    width: 100%;
  }
  .order-history .rdrCalendarWrapper {
    width: 100%;
  }
}
.order-history-details {
  padding: 1rem;
}
.order-history-details .text-primary {
  color: #002469 !important;
}
.order-history-details .text-bolder-primary {
  color: #002469;
  font-weight: bolder;
}
.order-history-details .order-number {
  text-decoration: underline;
  color: #002469;
}

.oceanxx {
  color: #000000;
  fill: #ffffff;
  opacity: 1;
  stroke: #000;
  stroke-width: 0.5;
  stroke-miterlimit: 1;
}

.noxx {
  fill: #e0e0e0;
  opacity: 0;
  stroke: #000000;
  stroke-width: 0.5;
}

.unxx {
  fill: #e0e0e0;
  stroke: #000000;
  stroke-width: 0.5;
  visibility: hidden;
}

.subxx {
  opacity: 0;
  stroke-width: 0.3;
}

.circlexx {
  fill: #e0e0e0;
  opacity: 0;
  stroke: #000000;
  stroke-width: 0.5;
}

.antxx {
  fill: #e0e0e0;
  fill-rule: evenodd;
  stroke: #ffffff;
  stroke-width: 0;
}

.limitxx {
  fill: #e0e0e0;
  fill-rule: evenodd;
  stroke: #ffffff;
  stroke-width: 0;
  visibility: hidden;
}

.coastxx {
  fill: #e0e0e0;
  fill-rule: evenodd;
  stroke: #ffffff;
  stroke-width: 0.3;
}

.landxx {
  fill: #e0e0e0;
  fill-rule: evenodd;
  stroke: #ffffff;
  stroke-width: 0.5;
}

.landxx:hover {
  fill: #74c5ff !important;
  background-color: #002469;
}

.cms-selector {
  margin: 0.5rem 0;
}

.cms .css-b74x3u-MuiList-root {
  width: 100%;
  max-width: 100%;
  border: 1px solid;
  margin: 1rem 0;
}

.cms table.table-responsive {
  border-collapse: separate;
  text-indent: initial;
  border-spacing: 2px;
  border: 1px solid;
  border-color: #8e8e8e;
  font-size: 12px;
}
.cms table.table-responsive .tableBorderRight {
  border-right: dotted 1px grey;
  padding: 0rem 0.5rem;
}
.cms table.table-responsive .tableBorderBottom {
  border-bottom: dotted 1px grey;
  padding: 0.5rem 0.5rem;
}

.cms table.table-responsive tr {
  display: table-row;
  border-color: inherit;
}

.box-frame {
  padding: 30px 15px;
  border: 2px solid rgba(0, 0, 0, 0.75);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.35) !important;
  position: relative;
}

.list-group {
  border: 2px solid rgba(0, 0, 0, 0.75);
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: none !important;
}

.box-frame .list-group-item {
  transition: transform 0.7s;
  font-size: 0.75rem;
  /* padding: 20px 10px */
  color: #ffffff;
  border-top-width: 0 !important;
  cursor: pointer;
  border: 2px solid rgba(0, 0, 0, 0.75);
  padding-top: 5px;
}

.box-frame .list-group-item label,
.box-frame .list-group-item div {
  color: #000000;
  background: #868686;
  background: -moz-linear-gradient(top, #868686 0%, #ececec 15%, #ececec 65%, #5e5e5e 100%);
  background: -webkit-linear-gradient(top, #868686 0%, #ececec 15%, #ececec 65%, #5e5e5e 100%);
  background: linear-gradient(to bottom, #868686 0%, #ececec 15%, #ececec 65%, #5e5e5e 100%);
  padding: 0px 5px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.6);
  clip-path: polygon(2.5% 0%, 97.5% 0%, 100% 170%, 0% 170%);
  width: 75%;
  text-align: center;
  height: fit-content;
  font-size: 90%;
  width: 90%;
  min-width: 220px;
}

.box-frame .list-group-item .badge {
  padding: 2px 3px;
}

.box-frame .list-group-item:focus {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.35) !important;
  z-index: 1054;
}

.box-frame .list-group-item:active {
  transform: scale(1.4);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.35) !important;
  z-index: 1054;
}

.box-frame .list-group-item:hover {
  transform: scale(1.001);
  transition: transform 0.1s;
  /* transition-delay: 0.1s */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.35) !important;
  z-index: 1054;
  background-color: transparent;
  border-color: transparent;
}

.box-frame .list-group-item.active {
  transition: transform 1.1s;
  transform: scale(1.4);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.35) !important;
  z-index: 1054;
  background-color: transparent;
  border-color: transparent;
}

.indexZ {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  z-index: 1054;
}

.overflowScroll {
  max-height: calc(100vh - 170px);
  overflow: auto;
}

.toolThumbnail:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  cursor: pointer;
  z-index: 1054;
}

input.empty {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
}

.cabinetBadge {
  position: absolute;
  top: 2px;
  left: 15px;
}

.cabinetBadge img {
  width: 75px;
}

.box-frame-bolt-rt img,
.box-frame-bolt-rb img,
.box-frame-bolt-lb img {
  width: 10px;
}

.box-frame-bolt-rt {
  position: absolute;
  right: 10px;
  top: 0px;
}

.box-frame-bolt-rb {
  position: absolute;
  right: 10px;
  bottom: 5px;
}

.box-frame-bolt-lb {
  position: absolute;
  left: 10px;
  bottom: 5px;
}

.s-smallSized-1 {
  height: 30px;
}

.smallSized-1 {
  height: 50px;
}

.s-mediumSized-1 {
  height: 75px;
}

.mediumSized-1 {
  height: 85px;
}

.s-largeSized-1 {
  height: 145px;
}

.largeSized-1 {
  height: 205px;
}

.s-smallSized-2 {
  height: 30px;
}

.smallSized-2 {
  height: 50px;
}

.s-mediumSized-2 {
  height: 80px;
}

.mediumSized-2 {
  height: 95px;
}

.s-largeSized-2 {
  height: 190px;
}

.largeSized-2 {
  height: 205px;
}

.s-smallSized-3 {
  height: 30px;
}

.smallSized-3 {
  height: 45px;
}

.s-mediumSized-3 {
  height: 60px;
}

.mediumSized-3 {
  height: 85px;
}

.s-largeSized-3 {
  height: 145px;
}

.largeSized-3 {
  height: 205px;
}

.textEllipsis {
  text-overflow: ellipsis;
  color: #505050;
}

.brandLogoDrawer {
  width: auto;
  height: 6px;
  margin-top: 5px;
  margin-left: 10px;
  float: left;
}

.activeToolImg {
  max-height: 375px;
}

.thumbToolImg {
  max-height: 75px;
  padding: 5px;
}

.activeTool {
  border-color: #0d6efd !important;
}

.drawerCounter {
  margin-top: 2px;
  margin-right: 10px !important;
}

.password-rules {
  padding: 1rem !important;
  font-style: italic;
}
.password-rules label {
  font-weight: bold;
  background-color: #f1f1f1;
  display: flex;
  flex-grow: 1;
  padding: 0.5rem 1rem;
}
.password-rules li {
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
}
.password-rules .icon {
  height: 16px;
  margin: 0.4rem;
  display: flex;
}
.password-rules .success {
  color: #008000;
}
.password-rules .error {
  color: #ff0000;
}
.password-rules .additional-password {
  font-style: italic;
  background-color: #f1f1f1;
  padding: 0 1rem 0 0;
}
.password-rules span {
  font-weight: bold;
  display: flex;
  flex-grow: 1;
  padding: 0.5rem 1rem;
  color: #000;
}
.password-rules li {
  padding: 1rem;
}

.error-text {
  color: #d32f2f;
  font-size: 0.75rem;
  margin-left: 0.85rem;
  letter-spacing: 0.03333em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.error-text.customization ul {
  list-style: circle;
  margin-left: 3rem;
  margin-right: auto;
}

.error-text.customization u {
  text-decoration: underline;
}

.info-text {
  color: #737373;
  font-size: 0.75rem;
  margin-left: 0.85rem;
  letter-spacing: 0.03333em;
}

.css-wgai2y-MuiFormLabel-asterisk {
  color: #ff0000;
}

.asterix::after {
  content: " *";
  color: #ff0000;
}

.header__top-nav {
  font-family: Segoe UI;
}

.header__menu {
  font-family: Segoe UI;
}

.bread-and-message {
  font-family: Segoe UI;
}

.item__details {
  font-family: Segoe UI;
}

.order-history {
  font-family: Segoe UI;
}

.wish-list {
  font-family: Segoe UI;
}

.quick-order {
  font-family: Segoe UI;
}

.my-preferences {
  font-family: Segoe UI;
}

.contact-us {
  font-family: Segoe UI;
}

.user__registration {
  font-family: Segoe UI;
}

.login-comp {
  font-family: Segoe UI;
}

.cart-fonts {
  font-family: Segoe UI;
}

.left-menu {
  font-family: Segoe UI;
}

.product-listing {
  font-family: Segoe UI;
}

.check-out {
  font-family: Segoe UI;
}

.WebpartParamSelector {
  font-family: Segoe UI;
}

.srs-custom-button, .srs-custom-button:hover {
  background-color: #001A70;
  color: #fff !important;
}

.btn-primary, .btn-primary:hover {
  background: #001A70;
  color: #fff !important;
  border: 1px solid #dddddd;
}

.css-189e9pj-MuiButtonBase-root-MuiButton-root {
  background: #001A70 !important;
  color: #fff !important;
}

.btn-check:focus + .btn, .btn:focus {
  box-shadow: unset !important;
}

.carousel .carousel-indicators button {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #001A70;
  margin-bottom: -15px;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23343a40' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
  background-size: 50%;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23343a40' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
  background-size: 50%;
}

.carousel-product-image .carousel-indicators {
  bottom: -10px;
}

.carousel-welcome {
  padding-bottom: 20px;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control:focus {
  color: #001A70;
  background-color: #fff;
  border: 1px solid #ced4da;
  outline: 1;
  box-shadow: unset;
}

@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1700px;
  }
}
.alert {
  position: fixed;
  top: 10%;
  right: 0;
  width: 40%;
  z-index: 999 !important;
}

/* For Mobile View */
@media screen and (min-width: 320px) and (max-width: 991px) {
  .alert {
    width: 70%;
  }
}
.breadcrumb {
  padding: 0.2rem 0;
  margin-bottom: 0rem;
  display: flex;
  flex-direction: row;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}

@media (max-width: 768px) {
  .breadcrumb {
    flex-direction: column;
  }
}
.bread-and-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .bread-and-message {
    flex-direction: column;
    align-items: flex-start;
  }
}
.back-to-top {
  padding: 1rem 0;
  cursor: pointer;
}
.back-to-top .back-to-top-inactive .text {
  display: none;
}
.back-to-top .back-to-top-inactive:hover .text {
  display: block;
  background-color: #f2f2f2;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.35);
  border-color: #d7d7d7;
  border-radius: 56px;
  width: 105.9px;
  text-align: left;
  font-size: 11px;
  padding: 0.5rem 0.1rem 0.5rem 0.9rem;
  position: absolute;
  z-index: -99;
}

.lds-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #cef;
  border-color: #cef transparent #cef transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-container {
  overflow-y: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.loader {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  position: absolute;
}

.downloadItem {
  margin: 0 0 0 1rem;
  text-decoration: underline;
  color: #0d6efd;
}
.downloadItem:hover {
  color: #0d6efd;
}

.shippingToOtherCountry {
  margin-top: 8px;
  padding: 4px 8px;
  background-color: #e6e6e6;
}
.shippingToOtherCountry a {
  color: #0d6efd;
  text-decoration: underline;
}
.shippingToOtherCountry a:hover {
  color: #0d6efd;
}

.imagesDetail {
  width: 80%;
  height: auto;
  background: #fff;
}

@media screen and (max-width: 767px) {
  .imagesDetail {
    width: 92vw;
  }
}
.swiper-slide {
  height: fit-content !important;
}

.container__zoomed_image {
  justify-content: space-around;
}

.js-image-zoom__zoomed-image {
  box-shadow: 0px 10px 39px -19px rgb(0, 0, 0);
  border: 5px solid #fff;
  border-radius: 10px;
  background-color: #fff;
}

.btn-zoom-close-mobile-zoomedImg {
  background: #eaeaea;
  border: 1px solid #ccc;
  border-radius: 10px 10px 0 0;
  width: 100px;
  display: none;
  margin-right: 15vw;
  box-shadow: 11px -7px 23px -15px rgba(0, 0, 0, 0.75);
  font-size: 14px;
  height: 32px;
}

.container__zoomed_image {
  display: flex;
  align-items: center;
}

.image-disclaimer {
  text-align: center;
  font-weight: bold;
}

.doc-title-4 {
  padding: 15px 15px 8px 15px !important;
  margin: 0 !important;
  font-size: 18px !important;
  font-weight: bold !important;
}

.doc-list-ul {
  list-style: disc;
  padding-left: 35px;
}

.doc-list-ul li a {
  color: #0d6efd;
  text-decoration: underline;
}

.doc-list-ul li a:hover {
  color: white;
  text-decoration: underline;
}

.footer {
  background: unset;
  color: unset;
  border-top: 1px solid #000;
  margin: auto;
  padding: 1rem 0;
  clear: both;
}
.footer #footerPrivacySettings {
  text-decoration: underline;
  cursor: pointer;
}
.footer a {
  color: unset;
  text-decoration: underline;
}
.footer__logo {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  text-align: right;
}
.footer .bosch_logo {
  width: 4.5rem;
  margin-left: auto;
}

/* For Mobile View */
@media screen and (min-width: 320px) and (max-width: 991px) {
  .footer a {
    padding: 0.4rem 0 0.4rem;
  }
  .footer__logo {
    margin: 1rem 0;
    /* display: flex */
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .footer a:not(:first-child) {
    margin: 0;
  }
}
.password-rules {
  padding: 1rem !important;
  font-style: italic;
}
.password-rules label {
  font-weight: bold;
  background-color: #f1f1f1;
  display: flex;
  flex-grow: 1;
  padding: 0.5rem 1rem;
}
.password-rules li {
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
}
.password-rules .icon {
  height: 16px;
  margin: 0.4rem;
  display: flex;
}
.password-rules .success {
  color: #008000;
}
.password-rules .error {
  color: #ff0000;
}
.password-rules .additional-password {
  font-style: italic;
  background-color: #f1f1f1;
  padding: 0 1rem 0 0;
}
.password-rules span {
  font-weight: bold;
  display: flex;
  flex-grow: 1;
  padding: 0.5rem 1rem;
  color: #000;
}
.password-rules li {
  padding: 1rem;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
  margin: auto;
}
.header__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.header__row .logo {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.header__logo {
  flex: 0 0 50%;
}
.header__search {
  display: inline-block;
  margin-left: auto;
}
.header__search > a {
  margin-left: 0.6rem;
}
.header__links {
  display: flex;
  flex-grow: 1;
  background-color: #001A70;
  padding: 0.3rem 1rem 0.3rem 1rem;
  align-items: center;
  font-weight: 600;
}
.header__top-nav-mobile .header__top-nav {
  display: none;
}
.header__button, .header__button:hover {
  color: white;
  text-decoration: none;
  padding-right: 1rem;
}
.header__top-nav {
  justify-content: flex-end;
}
.header__myAccount {
  flex-grow: 0 !important;
}
.header__welcome-text {
  color: #000;
  font-weight: bold;
  margin-bottom: 10px;
}
.header__input-group-append {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-17%, -50%);
  z-index: 800;
}
.header__right-menu {
  padding-right: 10px;
}
.header__right-menu .header-icon {
  font-size: 1.4rem;
}
.header__right-menu #lblCartItemsCount {
  position: absolute;
  bottom: 30px;
  min-width: 16px;
  width: auto;
  height: 10px;
  left: 14%;
  transform: translate(40%, 40%);
}
.header__right-menu span#lblLoanCartItemsCount {
  position: absolute;
  bottom: 32px;
  min-width: 16px;
  width: auto;
  height: 10px;
  left: -13%;
  transform: translate(40%, 40%);
  text-align: center;
}
.header__right-menu a#headerLoanCartIcon {
  top: 6px;
}
.header__right-menu #CartItemCount {
  display: flex;
  font-size: 15px !important;
  border-radius: 50%;
  justify-content: center;
}
.header__right-menu span.srs-icon-loan-hand.font-lg {
  position: relative;
  top: auto;
}
.header__right-menu #LoanCartItemCount {
  display: flex;
  font-size: 15px !important;
  border-radius: 50%;
  justify-content: center;
}
.header__right-menu .header__button.mobile {
  display: none;
}
.header__lang {
  position: absolute;
}
.header__lang .selected {
  width: 50px;
  background-color: #001A70;
  color: #fff;
  padding: 5px;
  font-weight: 700;
  font-size: 12px;
  border-radius: 2px;
  margin-right: 20px;
  cursor: pointer;
}
.header__lang .dropdown {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 2px;
  padding: 5px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.header__lang .dropdown li {
  text-align: center;
  font-size: 12px;
  padding: 5px;
  cursor: pointer;
}
.header__lang .dropdown li:hover {
  color: #001A70;
}
.header .top-log-menu {
  display: inline;
}
.header .log-out {
  text-decoration: none;
  padding: 0;
  color: white;
}
.header .edit-content-button {
  text-decoration: none;
  padding: 0 0 0 1.6rem;
  color: white;
}
.header .cart-icon {
  height: 26px;
}
.header .te {
  display: block;
}
.header .help-icon {
  display: none;
}

/* For Mobile View */
@media screen and (min-width: 320px) and (max-width: 991px) {
  .help-icon {
    display: block !important;
  }
  a.login-label {
    color: #000;
  }
  .te {
    display: none !important;
  }
  .header__button {
    color: #ff0000;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.3rem 0;
  }
  .header__links {
    padding: 0;
    background-color: unset;
  }
  .header__row {
    align-items: center;
    display: flex !important;
  }
  .header__top-nav-mobile .header__top-nav {
    display: contents;
    flex-direction: column;
    align-content: flex-start;
    font-weight: bold;
    padding: 0.2rem 0;
    border-top: 1px solid #000;
  }
  .header__top-nav-mobile .header__top-nav > a {
    margin-left: 0rem;
    color: #343a40;
  }
  .header__button, .header__button:hover {
    color: #000;
    text-decoration: none;
    padding-right: 1rem;
  }
  .header__myAccount {
    padding: 0 !important;
    margin: 0.4rem 0px;
  }
  .header__top-nav-desktop .header__top-nav {
    display: none;
  }
  .header__right-menu {
    text-align: start;
    width: 100%;
  }
  .header__right-menu .desktop-cart {
    display: none;
  }
  .header__right-menu .header__button.mobile {
    display: flex;
    border-top: 1px solid #6c757d;
    border-bottom: 1px solid #6c757d;
    padding-top: 8px;
  }
  .header__right-menu .header__button.mobile .srs-icon-help-circle {
    font-size: 26px;
    padding: 5px;
    margin: 1px;
    font-weight: bolder;
  }
  .header__right-menu #lblCartItemsCount {
    position: absolute;
    bottom: 35px;
    min-width: 16px;
    width: auto;
    height: 10px;
    left: 11.5%;
    transform: translate(40%, 70%);
  }
  .header__right-menu span.srs-icon-loan-hand.font-lg {
    position: relative;
    top: 13px;
  }
  .header__right-menu #CartItemCount {
    display: flex;
    font-size: 15px !important;
    border-radius: 50%;
    justify-content: center;
  }
  .header__right-menu #LoanCartItemCount {
    display: flex;
    font-size: 15px !important;
    border-radius: 50%;
    justify-content: center;
  }
  .header__right-menu span.srs-icon-cart-circle.h3 {
    font-size: 1.8rem;
    margin-right: -0.5rem;
  }
  .header .top-log-menu {
    display: flex;
    flex-direction: column;
    align-items: self-start;
  }
  .header .log-out {
    font-weight: bold;
  }
  .header .edit-content-button {
    font-weight: bold;
    padding: 0.4rem 0 0 0;
  }
}
.list-group-item {
  padding: 0.2rem 1rem 0.2rem;
  font-size: 16px;
  line-height: 1.5;
}

/* For Mobile View */
@media screen and (min-width: 320px) and (max-width: 991px) {
  .left-menu {
    display: block;
    margin-left: 0;
    left: 0;
    position: fixed;
    top: 0;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    height: 100vh;
    width: 80%;
    overflow-y: auto;
    z-index: 999;
  }
  .left-menu .left-menu-close,
  .left-menu .left-menu-close:hover {
    position: absolute;
    right: 0.6rem;
    border-radius: 1rem;
    height: 30px;
    color: #000;
    background: #fff;
    width: 30px;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.list-group-item + .list-group-item.active {
  background: #001A70;
}

@font-face {
  font-family: "srs-font-icons";
  src: url("../../node_modules/srs.sharedcomponents/lib/esm/sass/custom-icons/srs-font-icons.eot?jktbfe");
  src: url("../../node_modules/srs.sharedcomponents/lib/esm/sass/custom-icons/srs-font-icons.eot?jktbfe#iefix") format("embedded-opentype"), url("../../node_modules/srs.sharedcomponents/lib/esm/sass/custom-icons/srs-font-icons.ttf?jktbfe") format("truetype"), url("../../node_modules/srs.sharedcomponents/lib/esm/sass/custom-icons/srs-font-icons.woff?jktbfe") format("woff"), url("../../node_modules/srs.sharedcomponents/lib/esm/sass/custom-icons/srs-font-icons.svg?jktbfe#srs-font-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=srs-icon-], [class*=" srs-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "srs-font-icons", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.srs-icon-wishlist-o:before {
  content: "\e911";
}

.srs-icon-help-circle:before {
  content: "\e920";
}

.srs-icon-cart:before {
  content: "\e909";
}

.srs-icon-cart-add:before {
  content: "\e908";
}

.srs-icon-cart-added:before {
  content: "\e906";
}

.srs-icon-cart-remove:before {
  content: "\e907";
}

.srs-icon-loan-cart:before {
  content: "\e90e";
}

.srs-icon-wishlist:before {
  content: "\e910";
}

.srs-icon-cart-circle:before {
  content: "\e90c";
}

.srs-icon-cart-add-circle:before {
  content: "\e90a";
}

.srs-icon-cart-added-circle:before {
  content: "\e90b";
}

.srs-icon-cart-remove-circle:before {
  content: "\e90d";
}

.srs-icon-loan-cart-circle:before {
  content: "\e90f";
}

.srs-icon-cart-add-color-circle .path1:before {
  content: "\e91a";
  color: rgb(27, 20, 100);
}

.srs-icon-cart-add-color-circle .path2:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(27, 20, 100);
}

.srs-icon-cart-add-color-circle .path3:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(0, 0, 255);
}

.srs-icon-cart-added-color-circle .path1:before {
  content: "\e917";
  color: rgb(27, 20, 100);
}

.srs-icon-cart-added-color-circle .path2:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(27, 20, 100);
}

.srs-icon-cart-added-color-circle .path3:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(0, 146, 69);
}

.srs-icon-cart-remove-color-circle .path1:before {
  content: "\e914";
  color: rgb(27, 20, 100);
}

.srs-icon-cart-remove-color-circle .path2:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(27, 20, 100);
}

.srs-icon-cart-remove-color-circle .path3:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(255, 0, 0);
}

.srs-icon-wishlist-added-color:before {
  content: "\e913";
  color: #f00;
}

.srs-icon-wishlist-add-color:before {
  content: "\e910";
  color: #b3b3b3;
}

.srs-icon-cart-add-color .path1:before {
  content: "\e902";
  color: rgb(27, 20, 100);
}

.srs-icon-cart-add-color .path2:before {
  content: "\e903";
  margin-left: -0.982421875em;
  color: rgb(0, 0, 255);
}

.srs-icon-cart-added-color .path1:before {
  content: "\e904";
  color: rgb(27, 20, 100);
}

.srs-icon-cart-added-color .path2:before {
  content: "\e905";
  position: absolute;
  top: 1px;
  left: 0;
  color: rgb(0, 146, 69);
}

.srs-icon-cart-remove-color .path1:before {
  content: "\e900";
  color: rgb(27, 20, 100);
}

.srs-icon-cart-remove-color .path2:before {
  content: "\e901";
  margin-left: -1.0361328125em;
  color: rgb(255, 0, 0);
}

.srs-icon-wishlistCart-add-color:before {
  content: "\e912";
  color: #b3b3b3;
}

.srs-icon-loan-hand:before {
  content: "\e914";
}

.srs-icon-loan-hand-plus:before {
  content: "\e91e";
}

.srs-icon-loan-hand-b-plus .path1:before {
  content: "\e915";
  color: rgb(0, 0, 0);
}

.srs-icon-loan-hand-b-plus .path2:before {
  content: "\e916";
  margin-left: -1.2041015625em;
  color: rgb(0, 0, 0);
}

.srs-icon-loan-hand-b-plus .path3:before {
  content: "\e917";
  margin-left: -1.2041015625em;
  color: rgb(0, 0, 255);
}

.srs-icon-loan-hand-added .path1:before {
  content: "\e918";
  color: rgb(0, 0, 0);
}

.srs-icon-loan-hand-added .path2:before {
  content: "\e919";
  /*margin-left: -1.265625em*/
  color: rgb(0, 0, 0);
}

.srs-icon-loan-hand-added .path3:before {
  content: "\e91a";
  margin-left: -1.265625em;
  color: rgb(0, 146, 69);
}

.srs-icon-cart-header:before {
  content: "\e91f";
}

.colorized::before {
  color: #002469;
}

@media screen and (min-width: 320px) and (max-width: 991px) {
  .srs-icon-cart-added-color .path2:before {
    position: absolute;
    top: 0;
    left: 50%;
    color: #009245;
    transform: translate(-50%, 0px);
  }
}
.welcome {
  display: flex;
  justify-content: center;
}
.welcome img.img-responsive {
  width: 100%;
  height: 100%;
}
.welcome img {
  padding: 0.4rem 0;
}
.welcome div#welcomeZoneImage img {
  width: 100% !important;
  min-height: 180px;
  height: auto !important;
  max-height: 100% !important;
}
.welcome div#mainZoneImage img {
  width: 100%;
  height: 100%;
}
.welcome div#additionalZone1Image img {
  width: 100%;
  height: auto;
}
.welcome div#additionalZone2Image img {
  width: 100%;
  height: auto;
}
.welcome div#additionalZone3Image img {
  width: 100%;
  height: auto;
}

.carousel-control-prev {
  opacity: 1;
}

.carousel-control-next {
  opacity: 1;
}

/* For Mobile View */
@media screen and (min-width: 320px) and (max-width: 991px) {
  .first-image img {
    width: 100%;
  }
}
.outOfStockMessage {
  color: #ff0000;
}

.cart {
  padding: 1rem 0 1rem;
}
.cart__header {
  display: initial;
  background-color: #fff !important;
  top: 0;
  z-index: 997;
}
.cart__header .sticky {
  position: sticky;
  background-color: #fff !important;
  top: 0;
  z-index: 997;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.cart__header .total-string {
  font-weight: bold;
}
.cart__header .total {
  color: #001A70;
  font-weight: bolder;
}
.cart__header .second-row {
  padding: 0.7rem 0.7rem 0;
}
.cart__header .second-row .divlnkUpdateStockAvailability {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}
.cart__header .second-row .action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: italic;
  font-size: 15px;
}
.cart__header .second-row .lnkCartUpdate,
.cart__header .second-row .lnkCartContinueShopping,
.cart__header .second-row .lnkCartClearCart {
  justify-content: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0 0 0 0.5rem;
  color: #343a40;
}
.cart .wishlist-item-move-to-cart {
  cursor: pointer;
}
.cart .qty-label {
  position: absolute;
  top: -8px;
  left: 44%;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.75rem;
  background: #fff;
  z-index: 99;
}
.cart a.lnkCartClearCart {
  background: #0d6efd !important;
}
.cart .product-counter input {
  margin: 0;
  padding: 16px 5px 16px 5px;
  height: auto;
}
.cart .product-counter button {
  background-color: #fff;
  border: 1px solid #ced4da;
  color: #000 !important;
}
.cart .product-counter button.left {
  width: 30px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.cart .product-counter button.right {
  width: 30px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 16px 5px 16px 5px;
  height: auto;
}
.cart .wishlist-row {
  padding: 0.7rem 1.5rem;
}

/* For Mobile View */
@media screen and (min-width: 320px) and (max-width: 991px) {
  .cart__header .second-row {
    padding: 0.5rem 0 0.5rem 0;
  }
  .cart__header .second-row .divlnkUpdateStockAvailability {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }
  .cart__header .second-row .action-buttons {
    text-align: center;
  }
  .cart__header .second-row .action-buttons .lnkCartUpdate,
  .cart__header .second-row .action-buttons .lnkCartContinueShopping,
  .cart__header .second-row .action-buttons .lnkCartClearCart {
    margin: 0.5rem 0;
  }
  .productNotAvailable {
    display: flex;
    flex-direction: column;
  }
  .wishlist-row {
    text-align: center;
  }
}
.registration {
  width: 76%;
  padding: 1rem 0 1rem;
  margin: 0 auto;
}

/* For Mobile View */
@media screen and (min-width: 320px) and (max-width: 991px) {
  .registration {
    width: 100%;
  }
  .registration .password-rules label {
    justify-content: center;
  }
  .registration .password-rules li {
    justify-content: space-between;
    padding: 0.4rem 0;
  }
}
.adyen-checkout__fieldset__fields {
  pointer-events: none;
  opacity: 0.4;
  background-image: linear-gradient(to top, #c3c3c4 0%, #e1e2e3 100%);
  color: #000;
  background-color: #c3c3c4;
}

.checkout .address-information {
  padding: 0.6rem 0;
  display: block;
}
.checkout .new-address-toggler {
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}
.checkout .order-summury {
  display: flex;
  justify-content: space-between;
  padding: 0.6rem 0.6rem;
}
.checkout .order-summury .value.total, .checkout .order-summury .label.total {
  font-weight: bold;
}
.checkout .order-summury p {
  justify-content: center;
  align-items: center;
  align-self: center;
}
.checkout .save-button {
  background: #001A70;
}
.checkout .instuction.accordion-body {
  padding: 0.6rem;
}
.checkout .accordion-button:not(.collapsed) {
  color: unset;
  background-color: unset;
  box-shadow: unset;
}
.checkout button.accordion-button {
  padding: 10px 0;
}

.ddlUCTMessage {
  font-size: 12px;
  color: #ff0000;
  margin-bottom: 20px;
}

@media (max-width: 1199px) {
  .shippingAddressOption {
    margin-top: 7px;
    margin-bottom: 8px;
  }
}
.shippingAddressOption:first-of-type {
  margin-top: 13px;
}

.quick-order {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.quick-order .selector {
  background-color: #f1f1f1;
  padding: 1rem 1rem 0.5rem;
}
.quick-order .selector .MuiInputBase-root {
  background: #fff;
}
.quick-order .wrapper {
  padding: 0 1rem 0.5rem;
}
.quick-order .wrapper .item-label {
  position: absolute;
  top: -10px;
  left: 13px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.8rem;
  background: #fff;
  width: 50px;
  padding: 0.1rem 1.1px 0 3px;
}
.quick-order .wrapper .qty-label {
  position: absolute;
  top: -1px;
  left: 44%;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.8rem;
  background: #fff;
  z-index: 99;
}
.quick-order .button-wrapper {
  padding: 1rem 0 0;
  border-top: 1px solid #f1f1f1;
}
.quick-order .cancel-button {
  cursor: pointer;
  background: unset;
  padding: 1rem;
  color: #000;
  width: 100%;
  border: 1px solid;
}
.quick-order .add-button {
  cursor: pointer;
  background: #001A70;
  padding: 1rem;
  color: #fff;
  width: 100%;
}
.quick-order .counter-quick-order input {
  margin: 0;
  padding: 16px 5px 16px 5px;
  height: auto;
}
.quick-order .counter-quick-order button.left {
  background-color: #fff;
  border: 1px solid #ced4da;
  width: 30px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #000 !important;
}
.quick-order .counter-quick-order button.right {
  background-color: #fff;
  border: 1px solid #ced4da;
  width: 30px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 16px 5px 16px 5px;
  height: auto;
  color: #000 !important;
}
.quick-order .selected-sku {
  padding: 16px;
  background: unset;
  border-radius: 10px;
  width: 100%;
}
.quick-order .remove-product {
  vertical-align: middle;
  font-size: 33px;
  color: #ff0000;
  padding: 13px 0;
  cursor: pointer;
}

/* For Mobile View */
@media screen and (min-width: 320px) and (max-width: 991px) {
  .quick-order .cancel-button {
    margin: 1rem 0 0 0;
  }
  .quick-order .add-button {
    margin: 1rem 0 0 0;
  }
  .quick-order .form-label {
    margin: 1rem 0 0 0;
  }
}
.carousel-product-image {
  padding-bottom: 20px;
}

.item-details {
  padding: 1rem;
}
.item-details .mobile-name {
  display: none;
}
.item-details .carousel-control-prev {
  z-index: 8;
  padding-right: 3rem;
}
.item-details .carousel-control-next {
  z-index: 8;
  padding-left: 3rem;
}
.item-details .image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid lightgray;
  text-align: center;
  height: 100%;
}
.item-details .image-wrapper .active.carousel-item {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  z-index: 7 !important;
}
.item-details .image-wrapper .small-image {
  width: fit-content !important;
}
.item-details .image-wrapper .medium-image {
  width: 300px !important;
  margin-left: 6%;
}
.item-details .image-wrapper .big-image {
  width: 480px !important;
  margin-left: 4%;
}
@media screen and (max-width: 980px) {
  .item-details .image-wrapper .big-image, .item-details .image-wrapper .medium-image {
    width: 240px !important;
    margin-left: 2%;
  }
}
.item-details #lblItemDescription ul {
  list-style: disc;
}
.item-details #lblItemDescription ol, .item-details #lblItemDescription ul {
  padding-left: 2rem;
}
.item-details .card-info {
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid lightgray;
}
.item-details .card-info .desktop-name {
  display: block;
}
.item-details .swiper-button-next {
  color: #343a40;
}
.item-details .swiper-button-prev {
  color: #343a40;
}
.item-details .price-rate {
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
}
.item-details .item-quantity input {
  margin: 0;
  padding: 16px 5px 16px 5px;
  height: auto;
}
.item-details .item-quantity button.left {
  background-color: #fff;
  border: 1px solid #ced4da;
  width: 30px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #000 !important;
}
.item-details .item-quantity button.right {
  background-color: #fff;
  border: 1px solid #ced4da;
  width: 30px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 16px 5px 16px 5px;
  height: auto;
  color: #000 !important;
}

/* For Mobile View */
@media screen and (min-width: 320px) and (max-width: 991px) {
  .item-details__add-to-cart, .item-details__add-to-wish {
    display: block;
  }
  .item-details .mobile-name {
    display: block;
  }
  .item-details .image-wrapper {
    text-align: center;
  }
  .item-details .image-wrapper img {
    position: relative;
    display: block;
  }
  .item-details .card-info .desktop-name {
    display: none;
  }
  .item-details .card-info a {
    color: blue !important;
    text-decoration: underline !important;
  }
}
@media screen and (max-width: 980px) {
  .w-sm-90 {
    width: 90% !important;
  }
}
.product-images-slider .swiper-slide {
  position: relative;
  width: 20%;
}
.product-images-slider .swiper-slide img {
  width: 80%;
}

.product-images-slider-thumbs .swiper-slide {
  cursor: pointer;
  border: 1px solid #dddbdb;
}
.product-images-slider-thumbs .swiper-slide-thumb-active {
  border-color: black;
}
.product-images-slider-thumbs-wrapper {
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  position: relative;
}
.product-images-slider-thumbs-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.my-preferences {
  width: 76%;
  padding: 1rem 0 1rem;
  margin: 0 auto;
}
@media (max-width: 991px) {
  .my-preferences {
    width: 100%;
  }
}
.my-preferences .accordion {
  padding: 1rem 0;
}
.my-preferences-body {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.my-preferences-body button.accordion-button {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.my-preferences .password-rules {
  padding: 1rem;
  font-style: italic;
}
.my-preferences .password-rules label {
  font-weight: bold;
  background-color: #f1f1f1;
  display: flex;
  flex-grow: 1;
  padding: 0.5rem 1rem;
}
.my-preferences .password-rules label li {
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
}
.my-preferences .password-rules label li .icon {
  height: 16px;
  margin: 0.4rem;
  display: flex;
}
.my-preferences .password-rules label li .success {
  color: #008000;
}
.my-preferences .password-rules label .additional-password li {
  padding: 1rem;
}
.my-preferences .customer-support-info {
  width: 100%;
  font-size: 14px;
  margin-top: 30px;
  text-align: center;
  line-height: 1.8em;
}

@media (max-width: 768px) {
  .accordion-body-retailer-my-preferences {
    padding: 0;
    margin-top: 10px;
  }
}

.password-rules {
  padding: 1rem !important;
  font-style: italic;
}
.password-rules label {
  font-weight: bold;
  background-color: #f1f1f1;
  display: flex;
  flex-grow: 1;
  padding: 0.5rem 1rem;
}
.password-rules li {
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
}
.password-rules .icon {
  height: 16px;
  margin: 0.4rem;
  display: flex;
}
.password-rules .success {
  color: #008000;
}
.password-rules .error {
  color: #ff0000;
}
.password-rules .additional-password {
  font-style: italic;
  background-color: #f1f1f1;
  padding: 0 1rem 0 0;
}
.password-rules span {
  font-weight: bold;
  display: flex;
  flex-grow: 1;
  padding: 0.5rem 1rem;
  color: #000;
}
.password-rules li {
  padding: 1rem;
}

.wish-list {
  padding: 1rem 0.8rem 1rem;
}
.wish-list__row {
  display: flex;
  align-items: center;
}
.wish-list__header {
  padding: 1rem 1rem;
  position: sticky;
  background-color: #fff;
  top: 0;
  z-index: 998;
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
}
.wish-list__header .total-string {
  font-weight: bold;
}
.wish-list__header .wishlist-item-total,
.wish-list__header .total {
  color: #001A70;
  font-weight: bolder;
}
.wish-list__header .second-row {
  padding: 0.5rem 0 0.5rem 0;
}
.wish-list__header .second-row .divlnkUpdateStockAvailability {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.wish-list__header .second-row .action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wish-list__header .second-row .lnkCartUpdate,
.wish-list__header .second-row .lnkCartContinueShopping,
.wish-list__header .second-row .lnkCartClearCart {
  cursor: pointer;
}
.wish-list .qty-label {
  position: absolute;
  top: -8px;
  left: 44%;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.75rem;
  background: #fff;
  z-index: 99;
}
.wish-list .header-links {
  padding: 0 1.05rem 0 0;
}
.wish-list .wishlist-item-move-to-cart {
  cursor: pointer;
}
.wish-list .product-counter input {
  margin: 0;
  padding: 16px 5px 16px 5px;
  height: auto;
}
.wish-list .product-counter button.left {
  background-color: #fff;
  border: 1px solid #ced4da;
  width: 30px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #000 !important;
}
.wish-list .product-counter button.right {
  background-color: #fff;
  border: 1px solid #ced4da;
  width: 30px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 16px 5px 16px 5px;
  height: auto;
  color: #000 !important;
}

/* For Mobile View */
@media screen and (min-width: 320px) and (max-width: 991px) {
  .wish-list__header .second-row {
    padding: 0.5rem 0 0.5rem 0;
  }
  .wish-list__header .second-row .divlnkUpdateStockAvailability {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .wish-list__header .second-row .action-buttons {
    text-align: center;
  }
  .wish-list .productNotAvailable {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .wishlist-row {
    text-align: center;
  }
  .wishlist-row .badge {
    white-space: unset;
  }
}
.order-history .rdrDateRangePickerWrapper {
  justify-content: center;
  width: 100%;
}
.order-history .rdrDateRangePickerWrapper .rdrCalendarWrapper .rdrDateRangeWrapper {
  display: none;
}
.order-history #demo-multiple-checkbox {
  padding: 21px;
}

/* For Mobile View */
@media screen and (min-width: 320px) and (max-width: 991px) {
  .order-history .rdrDateRangePickerWrapper {
    width: 100%;
  }
  .order-history .rdrCalendarWrapper {
    width: 100%;
  }
}
.order-history-details {
  padding: 1rem;
}
.order-history-details .text-primary {
  color: #002469 !important;
}
.order-history-details .text-bolder-primary {
  color: #002469;
  font-weight: bolder;
}
.order-history-details .order-number {
  text-decoration: underline;
  color: #002469;
}

.oceanxx {
  color: #000000;
  fill: #ffffff;
  opacity: 1;
  stroke: #000;
  stroke-width: 0.5;
  stroke-miterlimit: 1;
}

.noxx {
  fill: #e0e0e0;
  opacity: 0;
  stroke: #000000;
  stroke-width: 0.5;
}

.unxx {
  fill: #e0e0e0;
  stroke: #000000;
  stroke-width: 0.5;
  visibility: hidden;
}

.subxx {
  opacity: 0;
  stroke-width: 0.3;
}

.circlexx {
  fill: #e0e0e0;
  opacity: 0;
  stroke: #000000;
  stroke-width: 0.5;
}

.antxx {
  fill: #e0e0e0;
  fill-rule: evenodd;
  stroke: #ffffff;
  stroke-width: 0;
}

.limitxx {
  fill: #e0e0e0;
  fill-rule: evenodd;
  stroke: #ffffff;
  stroke-width: 0;
  visibility: hidden;
}

.coastxx {
  fill: #e0e0e0;
  fill-rule: evenodd;
  stroke: #ffffff;
  stroke-width: 0.3;
}

.landxx {
  fill: #e0e0e0;
  fill-rule: evenodd;
  stroke: #ffffff;
  stroke-width: 0.5;
}

.landxx:hover {
  fill: #74c5ff !important;
  background-color: #002469;
}

.cms-selector {
  margin: 0.5rem 0;
}

.cms .css-b74x3u-MuiList-root {
  width: 100%;
  max-width: 100%;
  border: 1px solid;
  margin: 1rem 0;
}

.cms table.table-responsive {
  border-collapse: separate;
  text-indent: initial;
  border-spacing: 2px;
  border: 1px solid;
  border-color: #8e8e8e;
  font-size: 12px;
}
.cms table.table-responsive .tableBorderRight {
  border-right: dotted 1px grey;
  padding: 0rem 0.5rem;
}
.cms table.table-responsive .tableBorderBottom {
  border-bottom: dotted 1px grey;
  padding: 0.5rem 0.5rem;
}

.cms table.table-responsive tr {
  display: table-row;
  border-color: inherit;
}

.box-frame {
  padding: 30px 15px;
  border: 2px solid rgba(0, 0, 0, 0.75);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.35) !important;
  position: relative;
}

.list-group {
  border: 2px solid rgba(0, 0, 0, 0.75);
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: none !important;
}

.box-frame .list-group-item {
  transition: transform 0.7s;
  font-size: 0.75rem;
  /* padding: 20px 10px */
  color: #ffffff;
  border-top-width: 0 !important;
  cursor: pointer;
  border: 2px solid rgba(0, 0, 0, 0.75);
  padding-top: 5px;
}

.box-frame .list-group-item label,
.box-frame .list-group-item div {
  color: #000000;
  background: #868686;
  background: -moz-linear-gradient(top, #868686 0%, #ececec 15%, #ececec 65%, #5e5e5e 100%);
  background: -webkit-linear-gradient(top, #868686 0%, #ececec 15%, #ececec 65%, #5e5e5e 100%);
  background: linear-gradient(to bottom, #868686 0%, #ececec 15%, #ececec 65%, #5e5e5e 100%);
  padding: 0px 5px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.6);
  clip-path: polygon(2.5% 0%, 97.5% 0%, 100% 170%, 0% 170%);
  width: 75%;
  text-align: center;
  height: fit-content;
  font-size: 90%;
  width: 90%;
  min-width: 220px;
}

.box-frame .list-group-item .badge {
  padding: 2px 3px;
}

.box-frame .list-group-item:focus {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.35) !important;
  z-index: 1054;
}

.box-frame .list-group-item:active {
  transform: scale(1.4);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.35) !important;
  z-index: 1054;
}

.box-frame .list-group-item:hover {
  transform: scale(1.001);
  transition: transform 0.1s;
  /* transition-delay: 0.1s */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.35) !important;
  z-index: 1054;
  background-color: transparent;
  border-color: transparent;
}

.box-frame .list-group-item.active {
  transition: transform 1.1s;
  transform: scale(1.4);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.35) !important;
  z-index: 1054;
  background-color: transparent;
  border-color: transparent;
}

.indexZ {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  z-index: 1054;
}

.overflowScroll {
  max-height: calc(100vh - 170px);
  overflow: auto;
}

.toolThumbnail:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  cursor: pointer;
  z-index: 1054;
}

input.empty {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
}

.cabinetBadge {
  position: absolute;
  top: 2px;
  left: 15px;
}

.cabinetBadge img {
  width: 75px;
}

.box-frame-bolt-rt img,
.box-frame-bolt-rb img,
.box-frame-bolt-lb img {
  width: 10px;
}

.box-frame-bolt-rt {
  position: absolute;
  right: 10px;
  top: 0px;
}

.box-frame-bolt-rb {
  position: absolute;
  right: 10px;
  bottom: 5px;
}

.box-frame-bolt-lb {
  position: absolute;
  left: 10px;
  bottom: 5px;
}

.s-smallSized-1 {
  height: 30px;
}

.smallSized-1 {
  height: 50px;
}

.s-mediumSized-1 {
  height: 75px;
}

.mediumSized-1 {
  height: 85px;
}

.s-largeSized-1 {
  height: 145px;
}

.largeSized-1 {
  height: 205px;
}

.s-smallSized-2 {
  height: 30px;
}

.smallSized-2 {
  height: 50px;
}

.s-mediumSized-2 {
  height: 80px;
}

.mediumSized-2 {
  height: 95px;
}

.s-largeSized-2 {
  height: 190px;
}

.largeSized-2 {
  height: 205px;
}

.s-smallSized-3 {
  height: 30px;
}

.smallSized-3 {
  height: 45px;
}

.s-mediumSized-3 {
  height: 60px;
}

.mediumSized-3 {
  height: 85px;
}

.s-largeSized-3 {
  height: 145px;
}

.largeSized-3 {
  height: 205px;
}

.textEllipsis {
  text-overflow: ellipsis;
  color: #505050;
}

.brandLogoDrawer {
  width: auto;
  height: 6px;
  margin-top: 5px;
  margin-left: 10px;
  float: left;
}

.activeToolImg {
  max-height: 375px;
}

.thumbToolImg {
  max-height: 75px;
  padding: 5px;
}

.activeTool {
  border-color: #0d6efd !important;
}

.drawerCounter {
  margin-top: 2px;
  margin-right: 10px !important;
}

* {
  box-sizing: border-box;
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
}

a {
  text-decoration: none;
  color: #343a40;
}

a:hover {
  color: #001A70;
}

/* Typography */
body {
  font-family: "Segoe UI";
  font-size: 16px;
  line-height: 1.5;
}

h1 {
  font-size: 40px;
  font-weight: 700;
}

/*This fixes bootstrap conflict with mui */
legend {
  float: unset;
}

.text-right {
  text-align: right !important;
}

.accordion-button,
.accordion-button::after {
  background-color: #f1f1f1 !important;
  color: #001A70 !important;
}

.accordion-button:focus {
  box-shadow: unset;
}
.accordion-button:focus * {
  margin: 0;
  padding: 0;
  font-size: 1rem;
}

.border-rounded-10 {
  border-radius: 10px !important;
}

.searchField i {
  position: absolute;
  right: 13px;
  top: -2px;
  padding: 10px 12px;
  pointer-events: none;
}

.w-200 {
  min-width: 200px;
}

/* ORDER HISTORY STYLES */
#myOrdersList .nav-pills .nav-link.active,
#myOrdersList .nav-pills .show > .nav-link {
  color: #212529;
  background-color: #fff;
  border-bottom: 1px solid #007bff;
  border-radius: 0;
}

#myOrdersList .nav-pills .nav-link,
#myOrdersList .nav-pills .show > .nav-link {
  color: #212529;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}

#myOrdersList .nav-pills .nav-link:hover:hover {
  border-bottom: 1px solid #004fff;
}

/* MEDIA QUERIES FOR ORDER HISTORY LIST */
@media (max-width: 400px) {
  .col-xs-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-xs-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
/* DEFAULT ACCORDION STYLES */
/*.accordionTitle {
 * margin: 0px !important;
 * border-bottom: 1px solid #007bff;
 * background-color: #ffffff; */
.accordionTitle i.fa {
  transition: 0.25s ease-in-out;
}

.accordionTitle:hover {
  border-bottom: 1px solid #004fff;
}

.accordionBody {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.collapsed {
  margin: 0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.rotate180 {
  transform: rotate(180deg);
}

/* DEFAULT ACCORDION MEDIA QUERIES */
/*@media (max-width: 767px) {
 * .tabs2Accordion .tab-pane {
 * display: block !important;
 * opacity: 1;
 * } */
@media (min-width: 768px) {
  .tabs2Accordion .accordionTitle {
    display: none !important;
    opacity: 1;
  }
  .tabs2Accordion .accordionTitle .tabs2Accordion .collapse {
    display: block !important;
    opacity: 1;
  }
}
/* SCROLL TO TOP OR BOTTOM STYLES */
.floatingBtn {
  position: fixed;
  opacity: 0.15;
  cursor: pointer;
  transition: 0.25s ease-in-out;
  z-index: 999;
}

.floatTop {
  right: 0;
}

.floatBottom {
  right: 0;
  bottom: 50px;
}

.floatingBtn:hover {
  opacity: 1;
}

@media (max-width: 767px) {
  .floatingBtn {
    position: fixed;
    opacity: 0.35;
    cursor: pointer;
    transition: 0.25s ease-in-out;
  }
  .floatTop {
    right: 0;
  }
  .floatBottom {
    right: 0;
    bottom: 50px;
  }
}
.border-rounded-bottom-10 {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.border-rounded-top-10 {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.border-rounded-left-10 {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.border-rounded-right-10 {
  border-bottom-right-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.searchField i {
  position: absolute;
  right: 13px;
  top: -2px;
  padding: 10px 12px;
  pointer-events: none;
}

.w-200 {
  min-width: 200px;
}

.font-sm {
  font-size: 0.8rem;
}

.font-md {
  font-size: 1rem;
}

.font-lg {
  font-size: 1.25rem;
}

.font-xl {
  font-size: 2rem;
}

.border-dashed {
  border: 1px #b2b2b2 dashed;
}

.productImage:hover {
  cursor: pointer;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  transition: 0.25s ease-in-out;
}

.maxW-28 {
  width: 28px;
}

.bg-light-2 {
  background-color: #f1f1f1 !important;
}

.filterPills span {
  font-size: 10px;
  font-weight: 600;
  opacity: 0.6;
}
.filterPills span:hover {
  cursor: pointer;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  transition: 0.25s ease-in-out;
  opacity: 1;
}
.filterPills span:last-child:hover, .filterPills span i.fa:hover {
  color: #dc3545;
}

#filterPanel .itemTotal::before {
  content: "(";
}

#filterPanel .itemTotal::after {
  content: ")";
}

@media (max-width: 767px) {
  #filterBtn i.fa {
    transform: rotate(90deg);
  }
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0px;
}

/* For Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.checkAvailableStatus {
  animation: rotateIcon 2s;
  /* USE BELOW STYLS IF REQUIRED FOR INFINITE ROTATION */
  animation-iteration-count: infinite;
}

.productListCard img {
  border-radius: 10px;
}

.productIamge:hover {
  cursor: pointer;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  transition: 0.25s ease-in-out;
}

.productListCard .card:hover,
#filterPanel .card:hover:first-child {
  border-color: #6c757d;
  transition: 0.25s ease-in-out;
}

@keyframes rotateIcon {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1800deg);
  }
}
/* ORDER HISTORY DETAILS STYLES */
#myOrdersList .nav-pills .nav-link.active,
#myOrdersList .nav-pills .show > .nav-link {
  color: #212529;
  background-color: #fff;
  border-bottom: 1px solid #007bff;
  border-radius: 0;
}

#myOrdersList .nav-pills .nav-link,
#myOrdersList .nav-pills .show > .nav-link {
  color: #212529;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}

#myOrdersList .nav-pills .nav-link:hover:hover {
  border-bottom: 1px solid #004fff;
}

/* MEDIA QUERIES FOR ORDER HISTORY LIST */
@media (max-width: 400px) {
  .col-xs-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-xs-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
/* DEFAULT ACCORDION STYLES */
/*.accordionTitle {
 * margin: 0px !important;
 * border-bottom: 1px solid #007bff;
 * background-color: #ffffff;
 *
 * .accordionTitle i.fa
 * transition: 0.25s ease-in-out
 *
 * .accordionTitle:hover
 * border-bottom: 1px solid #004fff */
.accordionBody {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.collapsed {
  margin: 0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.rotate180 {
  transform: rotate(180deg);
}

.accordion .card .card-header i.fa {
  transition: 0.25s ease-in-out;
}

.accordion .card .collapsed i.fa {
  transform: rotate(180deg);
}

/* DEFAULT ACCORDION MEDIA QUERIES */
/*@media (max-width: 767px) {
 * .tabs2Accordion .tab-pane {
 * display: block !important;
 * opacity: 1;
 * } */
@media (min-width: 768px) {
  .tabs2Accordion .accordionTitle {
    display: none !important;
    opacity: 1;
  }
  .tabs2Accordion .accordionTitle .tabs2Accordion .collapse {
    display: block !important;
    opacity: 1;
  }
}
/* SCROLL TO TOP OR BOTTOM STYLES */
.floatingBtn {
  position: fixed;
  opacity: 0.15;
  cursor: pointer;
  transition: 0.25s ease-in-out;
  z-index: 999;
}

.floatTop {
  right: 0;
}

.floatBottom {
  right: 0;
  bottom: 50px;
}

.floatingBtn:hover {
  opacity: 1;
}

@media (max-width: 767px) {
  .floatingBtn {
    position: fixed;
    opacity: 0.35;
    cursor: pointer;
    transition: 0.25s ease-in-out;
  }
  .floatTop {
    right: 0;
  }
  .floatBottom {
    right: 0;
    bottom: 50px;
  }
  /* PRODUCT DETAILS */
  .productGallery {
    width: 100%;
  }
  .slide a img {
    margin-right: 10px;
  }
  .slide a img:hover {
    transform: scale(1.2);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    transition: 0.25s ease-in-out;
  }
  .productsDiv {
    padding: 0 10px;
    width: 100%;
    overflow: hidden;
  }
  .productSuggested {
    width: 15%;
    float: left !important;
    margin: 0 5px 10px;
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 7px;
  }
}
.ajax-loader {
  /*visibility: hidden;*/
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 1500 !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}
.ajax-loader img {
  position: relative;
  top: 50%;
}

.img-responsive {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.break-word {
  word-break: break-all;
}

.mt-min-8 {
  margin-top: -8px !important;
}

@media (max-width: 767px) {
  .mt-min-8 {
    margin-top: 0px !important;
  }
}
@media (min-width: 992px) {
  .pl-lg-5, .px-lg-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 768px) {
  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important;
  }
}
a.box-links {
  color: #001A70 !important;
}

.css-nnbavb {
  width: auto !important;
}

label.MuiFormLabel-colorPrimary.MuiFormLabel-filled.MuiFormLabel-root.MuiInputLabel-animated.MuiInputLabel-formControl.MuiInputLabel-outlined.MuiInputLabel-root.MuiInputLabel-shrink.asterix.control-label.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root, label.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-root.MuiFormLabel-colorPrimary.MuiFormLabel-filled.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  background: #fff;
  padding: 0 0.3rem;
}

a.cart-qty-link:hover {
  color: #0d6efd;
  text-decoration: underline;
}

span.MuiInputLabel-asterisk.MuiFormLabel-asterisk {
  color: red;
}

a.box-links:hover {
  text-decoration: underline;
}

button#clearPassword {
  background: #fff !important;
  color: #000;
  margin: 0 10px 0 0;
}

.fit-content {
  width: fit-content;
}

.bulletActive ul {
  list-style: disc;
  padding-left: 2rem;
}

.bulletActive b {
  font-size: 0.85em;
}

/* utility: class to force links to default style */
.normal-link {
  color: revert;
  text-decoration: underline;
}

.normal-link:hover, .normal-link:visited, .normal-link:active {
  color: revert;
}

/* utility: class to force flex-direction row to column in mobile sm breakpoint */
.row-to-column-sm, .row.row-to-column-sm {
  flex-direction: row;
}

@media screen and (max-width: 767px) {
  .row-to-column-sm, .row.row-to-column-sm {
    flex-direction: column !important;
  }
}
.RepairProcedures-bg-gradient {
  background: #fefefe;
  background: -moz-linear-gradient(top, #fefefe 0%, #d3d3d3 100%);
  background: -webkit-linear-gradient(top, #fefefe 0%, #d3d3d3 100%);
  background: linear-gradient(to bottom, #fefefe 0%, #d3d3d3 100%);
}

.ui-autocomplete {
  width: 96%;
  padding: 0;
  z-index: 50;
}
.ui-autocomplete li {
  list-style-type: none;
  padding: 12px 15px;
  border: 1px solid #dedede;
  border-top: none;
  background-color: #ffffff;
}

.ui-autocomplete li:hover {
  background-color: #3c99d4;
  color: #ffffff;
}

.ui-helper-hidden-accessible {
  display: none;
}

.form-control:focus,
.accordion-button:focus,
.form-select:focus,
.form-check-input:focus {
  box-shadow: none !important;
}

/* utility: force text-center on mobile */
@media screen and (max-width: 767px) {
  .mobile-center {
    text-align: center;
    width: 100%;
  }
  .newsletter-form {
    flex-direction: column;
    align-items: flex-start !important;
  }
}
/* utililty: for blue link */
.force-blue-link a {
  color: #0a58ca !important;
}

.stockAvailableTomorrowSpan {
  color: #19b16a !important;
}

#additionalMenuItemsRight {
  padding-top: 5px;
}

.productDetailName {
  color: #6c757d;
}

#organizationOrderHistoryTable {
  max-height: 400px;
  overflow: auto;
}

#organizationOrderHistoryTable thead {
  position: sticky;
  top: 0;
}

.external-item-link {
  color: #002469;
  text-decoration: underline;
  cursor: pointer;
}

.card-info a {
  color: blue !important;
  text-decoration: underline !important;
}

#NoProductList a {
  text-decoration: underline;
}

#NoProductList .showLinkStyle {
  color: #0000ff;
}

.contact-info a {
  color: blue !important;
  text-decoration: underline !important;
}

#checkoutPaymentMethod label {
  align-items: start !important;
}

#checkoutPaymentMethod label span:first-child {
  padding-top: 2px !important;
}

#newsLetterConsentEmail-label + div {
  background: white !important;
}

.wishlist-row .img-thumbnail {
  width: 75%;
}

.itemListShortDescription * {
  white-space: normal;
}

.header__button {
  font-weight: bold !important;
  padding-right: 1.4rem !important;
}

